import _ from 'lodash'
import { testingLanguages } from '../../utils/constants'

export const UPDATE_TASK_SUCCESS_MESSAGE =
  'Task Result and Scores updated successfully'
export const UPDATE_TASK_ERROR_MESSAGE =
  'An Error occur updating the task, please do It again'
export const GET_NORM_GROUPS_ERROR_MESSAGE =
  'an Error occur getting the norm groups from Contentful'
const NONE = 'None'
const VALIDITY_ISSUE_MSG =
  'This was a real encounter with data validity issues.'
export const dataValidityOptions = [
  'This was a real encounter with no data validity issues.',
  'This was a real encounter with data validity issues.',
  'This was a practice encounter and does not contain real data.'
]
export const UPDATE_ARCHIVE_ENCOUNTER_SUCCESS_MESSAGE = "Encounter archived succesfully"
export const UPDATE_ARCHIVE_ENCOUNTER_ERROR_MESSAGE = "An error occurred archiving the encounter"
export const UPDATE_ACTIVATE_ENCOUNTER_SUCCESS_MESSAGE = "Encounter activated succesfully"
export const UPDATE_ACTIVATE_ENCOUNTER_ERROR_MESSAGE = "An error occurred activating the encounter"


export const genders = ['Male', 'Female', 'Other']
export const informantGenders = [
  'Male',
  'Female',
  'Non-Binary',
  'Decline to State'
]
export const handednessValues = ['Right', 'Left', 'Ambidextrous']
export const educationYears = [...Array(30).keys(), '30+']
export const agesOnEncounterDateYears = [...Array(88).keys(), '88+']
export const informantAgeOptions = [
  'under 25',
  '25-44',
  '45-64',
  '65-80',
  '81+'
]
export const isLivingWithExamineeOptions = ['Yes', 'No']
export const informantRelationshipOptions = [
  'Child (by blood, marriage, or adoption)',
  'Sibling (by blood, marriage, or adoption)',
  'Parent (by blood, marriage, or adoption)',
  'Spouse, Partner, or Companion (including ex-spouse, ex-partner, and fiancee)',
  'Daughter-In-Law or Son-In-Law',
  'Other relative (by blood, marriage, or adoption)',
  'Other (friend, neighbor, or equivalent)',
  'Paid caregiver, healthcare provider, or clinician'
]
export const examineeFrequencyOfContactOptions = [
  'Daily',
  'At least three times per week',
  'Weekly',
  'At least three times per month',
  'Monthly',
  'Less than once per month'
]
export const UPDATE_ENCOUNTER_SUCCESS = 'Encounter successfully updated.'
export const UPDATE_ENCOUNTER_ERROR =
  'Sorry, something went wrong saving the encounter data.'
  export const UPDATE_EXAMINEE_SUCCESS = 'Examinee successfully updated.'
  export const UPDATE_EXAMINEE_ERROR =
    'Sorry, something went wrong saving the examinee data.'


export const GENERATE_REPORT_ERROR =
  'Please complete examinee information (age, gender, and education) to view this Report.'

export const GENERATE_SCORES_ERROR =
  'Please complete Examinee age, gender, and education to view Adjusted Scores.'

export const CREATE_BHS_TASKS_ERROR =
  'Please complete Informant first name, last name, and relationship before adding Brain Health Survey results.'

export const GET_ENCOUNTERS_ERROR_MESSAGE =
  'Sorry, something went wrong getting the encounter list, please try again.'
export const GET_ENCOUNTER_ERROR_MESSAGE =
  'Sorry, something went wrong getting the encounter details, please try again.'
export const NORM_GROUPS_ERROR_MESSAGE =
  'Sorry, something went wrong getting the norm groups data, please try again later.'

export const REPORT_PREVIEW_ERROR_MESSAGE =
  'Sorry, something went wrong generating the report preview'

export const REPORT_DOWNLOAD_ERROR_MESSAGE =
  'Sorry, something went wrong downloading the report preview'

export const hasInfoForReport = encounter => {
  const examinee = encounter.Examinee
  return (
    examinee?.age &&
    _.isNumber(examinee?.yearsOfEducation) &&
    examinee?.gender &&
    examinee?.identifier
  )
}

export const hasInformantData = encounter => {
  const infornant = encounter.Informant
  return (
    infornant?.firstName &&
    infornant?.lastName &&
    infornant?.relationshipToExaminee
  )
}

export const getDataValidityIssueCheckState = (
  validityIssue,
  dataValidity,
  encounter
) => {
  const result = {
    value: false,
    disabled: false
  }
  if (dataValidity) {
    if (
      (validityIssue === NONE && dataValidity === VALIDITY_ISSUE_MSG) ||
      (validityIssue !== NONE && dataValidity !== VALIDITY_ISSUE_MSG)
    ) {
      result.disabled = true
    }

    if (validityIssue === NONE && dataValidity !== VALIDITY_ISSUE_MSG) {
      result.value = true
    } else if (validityIssue !== NONE && dataValidity === VALIDITY_ISSUE_MSG) {
      const currentIssue = encounter.dataValidityIssuesArray?.find(
        dataIssue => validityIssue === dataIssue.trim()
      )
      result.value = !!currentIssue
    }
  }
  return result
}

export const formatTestingLanguage = (language = '') => {
  const key = language?.toString()?.toLowerCase()
  const separators = ['-', '_']
  return (
    key?.split(new RegExp(separators.join('|'), 'g'))?.join('-') || language
  )
}

export const getTestingLanguageText = (language = '') => {
  const normilizedKey = formatTestingLanguage(language)
  const languageFound = testingLanguages[normilizedKey]
  if (languageFound) {
    return languageFound
  }
  return normilizedKey || 'English'
}

export const getTitle = examinee => {
  let text = 'Encounter:'
  if (examinee?.firstName || examinee?.lastName) {
    text = `${text} ${examinee.firstName || ''} ${examinee.lastName || ''},`
  }
  return `${text} ${examinee?.identifier || ''}`
}

export const addContentfulIdToNorms = item => ({
  ...item,
  contentfulId: item.sys.id
})
